<template>
  <div :class="$style.container">
    <div :class="$style.content">
      <div :class="$style.logoContainer">
        <div>
          <router-link to="/">
            <img :src="require('@/assets/img/logo-sm.png')" alt="" />
          </router-link>
        </div>
      </div>
      <Filters
        ref="filters"
        :class="$style.filters"
        @change="searchTracksDebounced(tracksQuery, $event)"
      />
      <SearchResults
        :class="$style.searchResults"
        :tracks="tracks"
        :query="tracksQuery"
        :searchTracksStatusPending="searchTracksStatusPending"
        @update:query="(q) => (tracksQuery = q)"
      />
    </div>
  </div>
</template>

<script>
import SearchResults from "./components/SearchResults.vue";
import Filters from "./components/Filters.vue";
import { apiStatusComputedFactory } from "@/api/helpers/apiStatusComputedFactory";
import { IDLE, PENDING, SUCCESS, ERROR } from "@/constants/apiStatus";
import { withAsync } from "@/helpers";
import { debounce, pick } from "lodash-es";
import { searchCoveredTracks } from "@/api/songApi";
import { nanoid } from "nanoid";

export default {
  name: "Covered",
  components: {
    Filters,
    SearchResults,
  },
  data() {
    return {
      searchTracksStatus: IDLE,
      tracks: [],
      tracksQuery: "",
    };
  },
  created() {
    this.queryResultsCache = new Map();
    this.searchTracksDebounced = debounce(this.searchTracks, 500);
  },
  watch: {
    tracksQuery(q) {
      this.searchTracksDebounced(q, this.$refs.filters.getCurrentFilters());
    },
  },
  computed: {
    ...apiStatusComputedFactory(["searchTracksStatus"]),
  },
  methods: {
    async searchTracks(query, filters) {
      console.log("search tracks", query, filters);
      const requestSearchId = nanoid();
      this.lastRequestSearchId = requestSearchId;
      this.searchTracksStatus = PENDING;
      const { response, error } = await withAsync(() =>
        searchCoveredTracks({ query, filters })
      );
      if (requestSearchId !== this.lastRequestSearchId) {
        console.log("Another request made, bailing out", {
          reqId: requestSearchId,
          lastReqId: this.lastRequestSearchId,
        });
      }
      console.log({ response, error });
      if (error) {
        this.searchTracksStatus = ERROR;
        return;
      }
      this.tracks = response;
      this.searchTracksStatus = SUCCESS;
    },
  },
};
</script>

<style lang="scss" module>
.container {
  max-width: 1400px;
  margin: 0 auto 0 auto;
  padding: 3rem;
  min-height: calc(100vh - 6rem);
}

.content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 100px 1fr;
}

.filters {
  grid-area: 1 / 1 / 3 / 2;
}

.searchResults {
  grid-area: 2 / 2 / 3 / 3;
}

.logoContainer {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: flex-end;
  a {
    // display: flex;
    // justify-content: flex-end;
  }
  img {
    max-width: 80%;
    display: block;
    height: auto;
    margin-left: auto;
  }
}
</style>